import { getCents, getEuros } from 'lib/price';

interface PriceProps {
  price: number | undefined;
  originalPrice?: null | number;
  striked?: null | boolean;
  bold?: boolean;
  xxl?: boolean;
  negative?: boolean;
  prefix?: string;
}

export default function Price({
  price,
  striked = false,
  bold = false,
  xxl = false,
  negative = false,
  prefix,
  ...rest
}: PriceProps) {
  return (
    <div className="flex">
      {prefix && <div className="pr-2 flex center">{prefix}</div>}
      <div
        style={{
          background: striked
            ? 'linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%)'
            : '',
        }}
        translate="no"
        className={`${bold && 'font-extrabold'} flex justify-start`}
        {...rest}
      >
        <span className={xxl ? 'text-4xl' : 'text-lg'}>
          {negative ? '- ' : ''}
          {getEuros(price)},
        </span>
        <span className={`${xxl ? 'text-base pt-1' : 'text-xs'}`}>{getCents(price)}</span>
      </div>
    </div>
  );
}
